import {
  GeotabConfigDTO,
  GeotabGroupId,
  UpdateGeotabConfigDTO,
} from '@/api/dto/geotab.dto';
import {
  getGeotabConfig,
  getGeotabGroups,
  syncGeotabUsers,
  updateGeotabConfig,
} from '@/api/geotab';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import { ElMessage } from 'element-plus';
import { reactive, ref, Ref, watch } from 'vue';

export function useGeotabConfigForm(orgId: Ref<number>) {
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const saveState = ref<FetchStates>(FetchStates.UNFETCHED);
  const syncState = ref<FetchStates>(FetchStates.UNFETCHED);

  const form = reactive({
    database: '',
    userName: '',
    password: '',
    requiredSecurityClearances: Array<string>(),
  });

  const allSecurityClearances = ref<GeotabGroupId[]>([]);

  const setConfig = (configDTO: GeotabConfigDTO) => {
    form.database = configDTO.auth.credentials.database;
    form.userName = configDTO.auth.credentials.userName;
    form.password = configDTO.auth.credentials.password;
    form.requiredSecurityClearances = configDTO.requiredSecurityClearances;
  };

  const fetchConfig = async () => {
    if (!orgId) {
      return;
    }

    fetchState.value = FetchStates.FETCHING;

    try {
      const config = await getGeotabConfig(orgId.value);
      setConfig(config);
    } catch (error) {
      console.error(error);
      alertError(error, 'Failed to fetch Geotab config.');

      fetchState.value = FetchStates.UNFETCHED;
    }

    try {
      allSecurityClearances.value = await getGeotabGroups(orgId.value);

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);
      alertError(error, 'Failed to fetch Geotab security groups.');

      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  fetchConfig();
  watch(orgId, fetchConfig);

  const saveConfig = async () => {
    if (!orgId) {
      return;
    }

    saveState.value = FetchStates.FETCHING;

    try {
      const updateObj: UpdateGeotabConfigDTO = {
        auth: {
          database: form.database,
          userName: form.userName,
          password: form.password,
        },
        requiredSecurityClearances: form.requiredSecurityClearances,
      };

      const newConfig = await updateGeotabConfig(orgId.value, updateObj);
      setConfig(newConfig);

      saveState.value = FetchStates.FETCHED;

      ElMessage.success('Successfully saved Geotab configuration!');
    } catch (error) {
      console.error(error);
      alertError(error, 'Failed to save Geotab config.');

      saveState.value = FetchStates.UNFETCHED;
    }
  };

  const syncUsers = async () => {
    if (!orgId) {
      return;
    }

    syncState.value = FetchStates.FETCHING;

    try {
      await syncGeotabUsers(orgId.value);

      syncState.value = FetchStates.FETCHED;

      ElMessage.success(
        'Successfully synced users from Geotab to AccidentPlan!',
      );
    } catch (error) {
      console.error(error);
      alertError(error, 'Failed to sync users.');

      syncState.value = FetchStates.UNFETCHED;
    }
  };

  return {
    fetchState,
    saveState,
    syncState,
    form,
    allSecurityClearances,
    setConfig,
    fetchConfig,
    saveConfig,
    syncUsers,
  };
}
