
import { useGeotabConfigForm } from '@/composables/useGeotabConfigForm';
import { defineComponent } from '@vue/runtime-core';
import { FetchStates } from '@/helpers/fetch-states';
import { ref } from 'vue';
import { defaultSecurityGroupNames } from '@/api/dto/geotab.dto';

export default defineComponent({
  name: 'GeotabConfig',
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const useForm = useGeotabConfigForm(ref(props.org['id']));

    return {
      ...useForm,
      FetchStates,
      defaultSecurityGroupNames,
    };
  },
});
