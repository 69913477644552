import { DefaultResponse } from './default-response';
import { CreateSingleOrgDTO } from './org';

export interface GeotabAuthDTO {
  credentials: {
    database: string;
    userName: string;
    password?: string;
    sessionId?: string;
    type: 'password' | 'session';
  };
  path?: string;
}

export interface GeotabConfigDTO {
  auth: {
    credentials: {
      database: string;
      userName: string;
      password: string;
    };
  };
  requiredSecurityClearances: string[];
}

export interface UpdateGeotabConfigDTO {
  auth?: {
    database: string;
    userName: string;
    password: string;
  };
  requiredSecurityClearances?: string[];
}

export interface GeotabSignupDTO {
  auth: GeotabAuthDTO;
  requiredSecurityClearances: string[];
  org: CreateSingleOrgDTO;
}

export interface GeotabAPIDefaultCreateDTO {
  companyName: string;
  companyAddress: string;
  countryCode: string;
  phoneNumber: string;
}

export interface GetGeotabConfigResponse extends DefaultResponse {
  integration: string;
  org_id: number;
  config: GeotabConfigDTO;
}

export interface GeotabGroupId {
  id: string;
  name?: string;
}

export interface GetGeotabGroupsResponse extends DefaultResponse {
  integration: string;
  org_id: number;
  groups: GeotabGroupId[];
}

export interface GeotabSyncUsersResponse extends DefaultResponse {
  added_user_count: number;
  updated_user_count: number;
  added_user_ids: number[];
  updated_user_ids: number[];
}

export const defaultSecurityGroupNames: Record<string, string> = {
  GroupEverythingSecurityId: 'Administrator',
  GroupSupervisorSecurityId: 'Supervisor',
  GroupUserSecurityId: 'User',
  GroupViewOnlySecurityId: 'View Only',
  GroupNothingSecurityId: 'Nothing',
  GroupDriveUserSecurityId: 'Drive App User',
};
